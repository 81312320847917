export const SET_ERROR_MESSAGES = 'SET_ERROR_MESSAGES';
export const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY';
export const RESET_STATE = 'RESET_STATE';
export const SET_MODAL_OPTION = 'SET_MODAL_OPTION';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_POLICY_DETAILS ='SET_POLICY_DETAILS';
export const SET_NAME_DETAILS ='SET_NAME_DETAILS';
export const SET_COUNTER ='SET_COUNTER';
export const SET_EMAIL_COUNTER ='SET_EMAIL_COUNTER';
export const SET_OTP_COUNTER ='SET_OTP_COUNTER';
export const SET_OTP_VERIFY_COUNTER = 'SET_OTP_VERIFY_COUNTER';
export const SET_VERIFICATION_COUNTER ='SET_VERIFICATION_COUNTER';
export const SET_OTP_DETAILS ='SET_OTP_DETAILS';
export const SET_ACCESS_CODE ='SET_ACCESS_CODE';
export const VERIFICATION_TYPE ='VERIFICATION_TYPE';
export const SET_IDENTITY_VERIFICATION_DETAILS = 'SET_IDENTITY_VERIFICATION_DETAILS';
