import * as actionTypes from './actionTypes';

export const errorMessages = (response) => {
  return { type: actionTypes.SET_ERROR_MESSAGES, response };
};

export const showModalDialog = (modalData) => {
  return { type: actionTypes.SET_MODAL_VISIBILITY, modalData };
};

export const ResetState = () => {
  return { type: actionTypes.RESET_STATE, undefined };
};

export const setUserDetails = (userData) => {
  return { type: actionTypes.SET_USER_DETAILS, userData };
};

export const setPolicyDetails = (response) => {
  return { type: actionTypes.SET_POLICY_DETAILS, response };
};

export const setNameDetails = (userData) => {
  return { type: actionTypes.SET_NAME_DETAILS, userData };
};

export const setCounter = (zipCounter) => {
  return { type: actionTypes.SET_COUNTER, zipCounter };
};

export const setEmailCounter = (emailCounter) => {
  return { type: actionTypes.SET_EMAIL_COUNTER, emailCounter };
};
export const setOtpCounter = (otpCounter) => {
  return { type: actionTypes.SET_OTP_COUNTER, otpCounter };
};
export const setOtpVerifyCounter = (otpVerifyCounter) => {
  return { type: actionTypes.SET_OTP_VERIFY_COUNTER, otpVerifyCounter};
};
export const setVerificationCounter = (verificationCounter) => {
  return { type: actionTypes.SET_VERIFICATION_COUNTER, verificationCounter };
};

export const setOtpDetails = (response) => {
  return { type: actionTypes.SET_OTP_DETAILS, response };
};

export const setAccessCode = (accessCode) => {
  return { type: actionTypes.SET_ACCESS_CODE, accessCode };
};

export const setVerificationType = (verifiedBy) => {
  return { type: actionTypes.VERIFICATION_TYPE, verifiedBy };
};

export const setIdentityVerificationDetails = (userData) => {
  return { type: actionTypes.SET_IDENTITY_VERIFICATION_DETAILS, userData };
};
