import cookies from 'browser-cookies';
import { v4 as uuid } from 'uuid';
import { RegexConstants } from 'Utils/appConstants';

export const allowOnlyDigits = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  return currentValue;
};

export const toBase64 = (string) => {
  return Buffer.from(string).toString('base64');
};

export const formatMobileNumber = (mobileNumber) => {
  return mobileNumber ? `(${mobileNumber.slice(0, 3)}) ${mobileNumber.slice(3, 6)}-${mobileNumber.slice(6, 10)}` : '';
};

export const fromBase64 = (string) => {
  var buff = new Buffer(string, 'base64');
  return buff.toString('ascii');
};
export const getVerificationDetails = () => {
  const store = require('../app-store/store');
  const userDetails = store.default.getState().registrationDataReducer;
  return { email: userDetails?.identityVerificationDetails?.userId ? userDetails.identityVerificationDetails.userId : userDetails?.userDetails?.email ? userDetails.userDetails.email : '',
    phone: userDetails?.identityVerificationDetails?.phoneNumber ? userDetails.identityVerificationDetails.phoneNumber : userDetails?.userDetails?.mobile ? userDetails.userDetails.mobile : '' };
};
export const getParameterByName = (name, search) => {
  const match = RegExp('[?&]' + name + '=([^&]*)').exec(search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

export const getObject = (p, o) => {
  return p.reduce(function (xs, x) {
    return xs && xs[x] ? xs[x] : null;
  }, o);
};

export const dateFormatter = (date) => {
  let value = date && date.split('-');
  let yearValue = value && value !== '' && value[0] && parseInt(value[0]);
  let monthValue = value && value !== '' && value[1] && parseInt(value[1]);
  let dateValue = value && value !== '' && value[2] && parseInt(value[2]);
  monthValue = monthValue <= 9 ? '0' + monthValue : monthValue;
  dateValue = dateValue <= 9 ? '0' + dateValue : dateValue;
  let formattedDate = monthValue + '/' + dateValue + '/' + yearValue;
  return formattedDate;
};

export function getSessionId() {
  let sessionId = cookies.get('TravSessionId');
  if (sessionId) {
    sessionId = sessionId.split('_')[0];
  }
  if (!sessionId) {
    sessionId = uuid();
    if (cookies) {
      cookies.set('TravSessionId', sessionId, {
        domain: '.travelers.com',
        path: '/',
      });
    }
  }
  return sessionId;
}

export const getRegistrationFlowDetails = () => {
  const store = require('../app-store/store');
  const registrationFlowDetails = store.default.getState().registrationDataReducer;
  return { registrationFlow: registrationFlowDetails?.identityVerificationDetails?.registrationFlow ? registrationFlowDetails.identityVerificationDetails.registrationFlow : registrationFlowDetails?.userDetails?.registrationFlow ? registrationFlowDetails.userDetails.registrationFlow : '' };
};

export const normalizeInput = (value, previousValue) => {
  if (!value) {
    return value;
  }
  let currentValue = value.replace(RegexConstants.phoneRegex, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length || value.length < previousValue.length) {
    if (cvLength < 4) {
      return currentValue;
    }
    if (cvLength < 7) {
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    }
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
  return previousValue;
};
