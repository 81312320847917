import { combineReducers } from 'redux';
import errorMessageReducer from './errorMessageReducer';
import modalDialogReducer from './modalDialogReducer';
import registrationDataReducer from './setUserDetailsreducer';
import counterReducer from './counterReducer';
import accessCodeReducer from './accessCodeReducer';
import verificationTypeReducer from './verificationTypeReducer';

const appReducer = combineReducers({
  errorMessageReducer,
  modalDialogReducer,
  registrationDataReducer,
  counterReducer,
  accessCodeReducer,
  verificationTypeReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
