import * as actionTypes from '../actions/actionTypes';

export default function counterReducer(state = [], action) {
  switch (action.type) {
    case actionTypes.SET_COUNTER:
      return { ...state, zipCounter: action.zipCounter };
    case actionTypes.SET_EMAIL_COUNTER:
      return { ...state, emailCounter: action.emailCounter };
    case actionTypes.SET_OTP_COUNTER:
      return { ...state, otpCounter: action.otpCounter };
    case actionTypes.SET_OTP_VERIFY_COUNTER:
      return { ...state, otpVerifyCounter: action.otpVerifyCounter};
    case actionTypes.SET_VERIFICATION_COUNTER:
      return { ...state, verificationCounter: action.verificationCounter };
    default:
      return state;
  }
}
