import * as actionTypes from '../actions/actionTypes';

export default function accessCodeReducer(state = [], action) {
  switch (action.type) {
    case actionTypes.SET_ACCESS_CODE:
      return { ...state, accessCode: action.accessCode };
    default:
      return state;
  }
}
