import * as actionTypes from '../actions/actionTypes';

export default function registrationDataReducer(state = [], action) {
  switch (action.type) {
    case actionTypes.SET_USER_DETAILS:
      return { ...state, userDetails: action.userData };
    case actionTypes.SET_POLICY_DETAILS:
      return { ...state, policyDetails: action.response };
    case actionTypes.SET_NAME_DETAILS:
      return { ...state, nameDetails: action.userData };
    case actionTypes.SET_OTP_DETAILS:
      return { ...state, otpDetails: action.response };
    case actionTypes.SET_IDENTITY_VERIFICATION_DETAILS:
      return { ...state, identityVerificationDetails: action.userData };
    default:
      return state;
  }
}
