export const ModalConstants = {
  infoModal: 'infoModal',
  cancelModal: 'cancelModal',
  smsModal: 'smsModal',
  emailandSmsModal: 'emailandSmsModal',
  confirmModal: 'confirmModal',
  timeoutModal: 'timeoutModal',
  findPolicyModal: 'findPolicyModal',
  multiplePoliciesModal: 'multiplePoliciesModal',
  confirmExitModal: 'confirmExitModal'
};

export const RouteConstants = {
  start: '/start',
  comingSoon: '/comingsoon',
  nameOnPolicy: '/name-on-policy',
  zipCode: '/zip-code',
  invite: '/invite',
  oneTimePin: '/one-time-pin',
  createAccount: '/create-account',
  emailVerification: '/email-verification',
  identityVerification: '/identity-verification',
  criticalError: '/critical-error',
  createUser: '/api/create',
  notfound: '/notfound',
  regSuccess: '/regSuccess'
};

export const phoneNumbers = {
  technicalSupport: '(877) 754-0481',
  callToTechnicalSupport: 'tel:+1-877-754-0481',
  billPaymentSupport: '(800) 842-5075',
  callToBillPaymentSupport: 'tel:+1-800-842-5075',
  claimReportSupport: '(800) 252-4633',
  callToClaimReportSupport: 'tel:+1-800-252-4633'
};

export const errorPageConstants = {
  otpResendMaxAttempts: 'otpResendMaxAttempts',
  zipCodeMaxAttempts: 'zipCodeMaxAttempts',
  emailLinkExpired: 'emailLinkExpired',
  criticalError: 'crtitcalError',
  identityVerificationMaxAttempts: 'identityVerificationMaxAttempts'
};

export const SourceSystemConstants = {
  '/start': 'let\'\sgetstarted',
  '/email-verification': 'emaillinksent',
  '/identity-verification': 'verifyidentity',
  '/one-time-pin': 'mobilepin',
  '/zip-code': 'zipcode',
  '/name-on-policy': 'nameonpolicy',
  '/create-account': 'createaccount',
  '/critical-error': 'problems',
  '/invite': 'zipcode',
  '/regSuccess':'registrationsuccess'
};

export const RegexConstants = {
  phoneRegex: /[^\d]/g
};

export const InviteFlowConstants = ['PURCHASERECEIPT', 'GEICOECONSENT',
  'GEICOECONSENTREMINDER', 'MIDTERMCONSENT', 'NBMIDTERMECONSENTREMINDER',
  'EQAPREMINDER', 'EQAPSTATUS', 'MYTPROMOEMAIL', 'ONBOARDINGSMS', 'BOOKTRANSFER'
];

export const EventCategory =
{
  PAGE_TAG: 'page tag'
};

export const EventActionConstants = {
  buttonClickAction: 'buttonclick',
  linkClickAction: 'linkclick',
  viewModalAction: 'viewmodal'
};

export const EventLabelConstants = {
  loginButtonLabel: 'loginbutton',
  moreInfoIconLabel: 'moreinfoicon',
  createAccountLabel: 'createaccount',
  cancelLabel: 'cancel',
  resendEmailLabel: 'resendemail',
  continueLabel: 'continue',
  helpDeskNumberLabel: 'helpdesknumber',
  findingPolAndBillAcctNumbLabel: 'findingpolandbillacctnumb',
  haveMultPolicesLabel: 'havemultpolicies',
  zipCodeHelpLabel: 'zipcodehelp',
  verifyLabel: 'verify',
  closeLabel: 'close',
  seePasswordLabel: 'seepassword',
  footerLinkLabel: 'footerlink'
};

export const EventDetailConstants = {
  nextPageDetail: 'nextpage',
  openDetail: 'open',
  cancelPageDetail: 'cancelpage',
  sendVerificationDetail: 'sendverification',
  sentEmailDetail: 'sentemail',
  openPhoneNumberDetail: 'openphoneno',
  closePageDetail: 'closepage',
  showDetail: 'show'
};

export const VerificationCategoryConstants = {
  landing: 'landing',
  policyEntry: 'policyentry',
  getStarted: 'let\'\sgetstarted',
  emailMobileVerificationOptPU: 'emailmobileverificationoptpu',
  mobilePin: 'mobilepin',
  emailLinkSent: 'emaillinksent',
  zipCode: 'zipcode',
  createAccount: 'createaccount',
  invitation: 'invitation',
  mobileVerifyPU: 'mobileverifypu',
  neustarIdProof: 'neustaridproof',
  verifyIdentity: 'verifyidentity'
};

export const VerificationActionConstants = {
  policyVerifierAction: 'policyverifier',
  sendVerificationEmailAction: 'sendverificationemail',
  emailAction: 'email',
  emailMdmCheckAction: 'emailmdmcheck',
  mobileAction: 'mobile',
  mobileMdmCheckAction: 'mobilemdmcheck',
  policyAssociationServiceAction: 'policyassociationservice',
  aimUserServiceAction: 'aimuserservice',
  successAction: 'success',
  authServiceAction: 'authservice',
  aimCommServiceAction: 'aimcommservice',
  neustarServiceAction: 'neustarservice',
  idProofingServiceAction: 'idproofingdbservice',
  sendVerificationAction: 'sendverification',
  resendPinAction: 'resendpin',
  exhaustedAttemptsAction: 'exhaustedattempts',
  exhaustedGenerateAction: 'exhaustedgenerateattempts',
  exhaustedVerifyAction: 'exhaustedverifyattempts',
  verifyAction: 'verify',
  linkExpiryAction: 'linkexpiry',
  passwordAction: 'password',
  accountCreatedAction: 'accountcreated',
  billingServiceAction: 'billingservice'
};

export const VerificationTypeConstants = {
  systemDown: 'systemdown',
  success: 'success',
  error: 'error',
  yes: 'yes',
  no: 'no',
  pass: 'pass',
  fail: 'fail',
  invalidPhone: 'invalidphone',
  notFilled: 'notfilled',
  match: 'match',
  expired: 'expired',
  notMatch: 'notmatch',
  valid: 'valid',
  inValid: 'invalid',
  userIdExists: 'useridexists',
  linkExpired: 'linkexpired',
  invite: 'invite',
  accountFound: 'accountfound',
  accountNotFound: 'accountnotfound',
  failure: 'failure',
  existingMytPI: 'existingmytpi',
  existingNonMytPI: 'existingnonmytpi',
  notExistingMytPI: 'notexistingmytpi',
  notExistingNonMytPI: 'notexistingnonmytpi'
};

export const ServiceErrorCodeConstants = {
  aimUserServiceErrCode: 'PIX1001',
  aimCommServiceErrCode: 'PIX1002'
};
