import * as actionTypes from '../actions/actionTypes';
const errorMessageReducer = (state = { text: '' }, action) => {
  switch(action.type) {
    case actionTypes.SET_ERROR_MESSAGES:
      return {
        ...state,
        text: action.response
      };
    default:
      return state;
  }
};
export default errorMessageReducer;
