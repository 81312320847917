import * as actionTypes from '../actions/actionTypes';

export default function verificationTypeReducer(state='',action){
  switch (action.type){
    case actionTypes.VERIFICATION_TYPE:
      return {...state, verifiedBy: action.verifiedBy};
    default:
      return state;
  }
}
