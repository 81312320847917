import { SourceSystemConstants, RegexConstants, RouteConstants, InviteFlowConstants, EventCategory } from '../utils/appConstants';
import { getParameterByName } from '../utils/helpers';
import { Utag } from './tealium';

export const ReportAnalytics = (() => {
  var registrationType, invitationType;

  const setRegFlow = () => {
    const path = window.location.pathname;
    if (path === process.env.PUBLIC_URL + RouteConstants.start || path === process.env.PUBLIC_URL + RouteConstants.zipCode || path === process.env.PUBLIC_URL + RouteConstants.invite) {
      if (path === process.env.PUBLIC_URL + RouteConstants.invite) {
        const search = window.location.search;
        let queryToken = getParameterByName('src', search);
        let inviteFlowValue = queryToken && InviteFlowConstants.indexOf(queryToken) > -1 ? queryToken.toLowerCase() : 'organic';
        registrationType = 'invited';
        invitationType = inviteFlowValue;
      } else {
        registrationType = 'organic';
      }
    }
  };

  const setAdditionalCustomDimensions = (additionalAttribute) => {
    setRegFlow();
    let additionalAttributeObj = { registration_type: registrationType,invitation_type: invitationType };
    if (additionalAttribute && Object.keys(additionalAttribute).length > 0) {
      additionalAttributeObj = {
        registration_flow: additionalAttribute.registrationFlow,
        registration_type: registrationType,
        invitation_type: invitationType,
        login_id: additionalAttribute?.email,
        phone_number: additionalAttribute?.phone?.replace(RegexConstants.phoneRegex, ''),
        verification_method: additionalAttribute?.verificationOption,
        zipcode: additionalAttribute?.zipcode,
        attempt_count: additionalAttribute?.numberOfAttempts,
        policy_number: additionalAttribute?.policyNumber,
        verification_code: additionalAttribute?.verificationCode,
        first_name: additionalAttribute?.firstName,
        last_name: additionalAttribute?.lastName,
        date_of_birth: additionalAttribute?.dateOfBirth,
        mdm_id: additionalAttribute?.mdmId
      };
    }
    return additionalAttributeObj;
  };

  const getPageName = () => {
    const currentPathName = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'), window.location.pathname.length);
    return SourceSystemConstants[currentPathName];
  };

  return {
    clickEvent: (eventAction, eventLabel, eventDetail, additionalAttribute, next) => {
      Utag.clickTag(getPageName(), EventCategory.PAGE_TAG, eventAction, eventLabel, eventDetail, setAdditionalCustomDimensions(additionalAttribute), next);
    },
    metaEvent: (verificationCategory, verificationAction, verificationType, additionalAttribute, next) => {
      Utag.metaTag(getPageName(), verificationCategory, verificationAction, verificationType, setAdditionalCustomDimensions(additionalAttribute), next);
    },
    navEvent: (additionalAttribute, next) => (err, errDesc) => {
      if (errDesc && errDesc !== '') {
        Utag.navTag(getPageName(), setAdditionalCustomDimensions(additionalAttribute), next)(err, errDesc);
      } else {
        Utag.navTag(getPageName(), setAdditionalCustomDimensions(additionalAttribute), next)();
      }
    },
    setRegFlow: setRegFlow
  };
})();
