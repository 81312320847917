import * as actionTypes from '../actions/actionTypes';

export default function modalDialogReducer(state = {canShow:false, selectedModal:''}, action) {
  switch (action.type) {
    case actionTypes.SET_MODAL_VISIBILITY:
      return {...state, modalDetails: action.modalData };
    default:
      return state;
  }
}
