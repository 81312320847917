import { getSessionId } from '../utils/helpers';

const appName = 'mytpi_registration';
const pageView = 'page_view';

//This function is used to retry the window.utag loaded successfully, else retry
//To handle the onload page view tags
const withUtag = async () => {
  if (window.utag) return window.utag;
  let i = 0;
  while (i <= 10) {
    await new Promise(resolve => setTimeout(resolve, 200));
    if (window.utag) return window.utag;
    i = i + 1;
  }
  return { link: () => { }, view: () => { } };
};

const Utag = {
  clickTag: (pageName, eventCategory, eventAction, eventLabel, eventDetail, additionalProps = {}, next = () => { }) => {
    withUtag().then(ut => {
      if (!ut || !ut.link || typeof ut.link !== 'function') {
        return;
      }
      ut.link({
        appid_cd: appName,
        page_name: pageName,
        event_action: eventAction,
        event_category: eventCategory,
        event_detail: eventDetail,
        event_label: eventLabel,
        trv_sessionid: getSessionId(),
        tealium_event: eventAction,
        ...additionalProps
      },
      setTimeout(next, 2500));
    });
  },

  metaTag: (pageName, verificationCategory, verificationAction, verificationType, additionalProps = {}, next = () => { }) => {
    withUtag().then(ut => {
      if (!ut || !ut.view || typeof ut.view !== 'function') {
        return;
      }
      ut.view({
        appid_cd: appName,
        page_name: pageName,
        verification_category: verificationCategory,
        verification_action: verificationAction,
        verification_type: verificationType,
        trv_sessionid: getSessionId(),
        tealium_event: pageView,
        ...additionalProps
      },
      setTimeout(next, 2500));
    });
  },

  navTag: (pageName, additionalProps = {}, next = () => { }) => (err, errDesc) => {
    withUtag().then(ut => {
      if (!ut || !ut.view || typeof ut.view !== 'function') {
        return;
      }
      ut.view({
        appid_cd: appName,
        page_name: pageName,
        knockout_error_description: errDesc || '',
        knockout_error_type: err ?? undefined,
        trv_sessionid: getSessionId(),
        tealium_event: pageView,
        ...additionalProps
      },
      setTimeout(next, 2500));
    });
  }
};

export { Utag };
