import Axios from 'axios';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Footer, Header } from 'trv-tds-react';
import { v4 as uuid } from 'uuid';
import * as StoreActions from 'Store/actions/storeActions';
import store from 'Store/store';
import Loader from 'Components/common/loader';
import CreateRoutes from './routes';
import './styles/app.scss';
import { getSessionId } from 'utils/helpers';
import { RouteConstants } from 'Utils/appConstants';
import { environment } from './environment';
import { ReportAnalytics } from 'analytics/common';
import { EventActionConstants, EventLabelConstants } from 'Utils/appConstants';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.accessCode = null;
  }

  componentWillMount() {

    Axios.interceptors.request.use((config) => {
      config.headers = {
        'Consumer-App-Name': 'aim-myt-registration-ui',
        'Trv-Correlation-Id': uuid(),
        'Trv-Session-Id': getSessionId(),
        'Access-Code': config.url === '/api/verifyuser' ? null : this.accessCode
      };
      config.baseURL =  window.location.origin + process.env.PUBLIC_URL;
      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    Axios.interceptors.response.use((response) => {
      if (response.headers['access-code'] !== undefined) {
        this.accessCode = response.headers['access-code'];
        store.dispatch(StoreActions.setAccessCode(this.accessCode));
      }
      return response;
    }, (error => {
      const serviceErrorCode = error?.response?.data?.error?.ServiceCode;
      if (serviceErrorCode === 'PIX1004' || serviceErrorCode === 'PIX1001' || serviceErrorCode === 'PIX1002') {
        this.accessCode = error.response.headers['access-code'];
        store.dispatch(StoreActions.setAccessCode(this.accessCode));
        return Promise.reject(error);
      } else if (error?.response?.status === 401) {
        window.location.href = process.env.PUBLIC_URL + RouteConstants.start;
      } else {
        if (error?.response?.data?.error?.Reason !== 'COM1001' && error?.response?.data?.error?.Reason !== 'COM1002') {
          if(!(error?.config?.url ===  window.location.origin + process.env.PUBLIC_URL + RouteConstants.createUser)) {
            window.location.href = process.env.PUBLIC_URL + RouteConstants.criticalError;
          }
        }
      }
      return Promise.reject(error);
    }));
  }

  componentDidMount() {
    store.dispatch(StoreActions.setCounter({ value: 0 }));
    store.dispatch(StoreActions.setEmailCounter({ value: 0 }));
    store.dispatch(StoreActions.setOtpCounter({ value: 0 }));
    store.dispatch(StoreActions.setOtpVerifyCounter({ value: 0}));
    store.dispatch(StoreActions.setVerificationCounter({ value: 0 }));
  }

  triggerCallAnalytics = (value) => {
    ReportAnalytics.clickEvent(EventActionConstants.linkClickAction, EventLabelConstants.footerLinkLabel, value);
  };

  render() {
    return (
      <div className="tds-app-wrapper">
        <Provider store={store}>
          <Header href="/" appName={<span>
            MyTravelers<sup>&reg;</sup> <p className="tds-mb-xx-small pireg-gloabalheader__align">for Personal Insurance</p>
          </span>}>
          </Header>
          <main className="tds-body">
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <Suspense fallback={<Loader />}>
                <CreateRoutes />
              </Suspense>
            </BrowserRouter>
          </main>
          <Footer>
            <Footer.Copy />
            <div className="tds-global-footer__links">
              <ul>
                <li><a id="termsOfService" className="tds-global-footer__link-item" href={environment.travelersLegalUrl} target="termsofservice" onClick={() => this.triggerCallAnalytics('termsofservice')}>Terms of Service</a></li>
                <li><a id="privacyAndSecurity" className="tds-global-footer__link-item" href={environment.travelersPrivacyUrl} target="privacysecurity" onClick={() => this.triggerCallAnalytics('privacysecurity')}>Privacy &amp; Security</a></li>
                <li><a id="accessibility" className="tds-global-footer__link-item" href={environment.travelersAccessibilityUrl} target="accessibility" onClick={() => this.triggerCallAnalytics('accessibility')}>Accessibility</a></li>
                <li><a id="coBrowse" className="cobrowse__start tds-global-footer__link-item" target="cobrowse" onClick={() => this.triggerCallAnalytics('cobrowse')}>Co-browse</a></li>
              </ul>
            </div>
          </Footer>
        </Provider>
      </div>
    );
  }

}

export default App;
